import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PageHeader from "../components/pageHeader";
import { graphql } from "gatsby";
import ListingList from "../components/ListingList";
import ListingContainer from "../components/ListingContainer";
import ListBusinessButton from "../components/ListBusinessButton";

function IndexPage({ data, pageContext: { name } }) {
  const listings = data.allBusinessListing.edges.map(({ node }) => node);

  return (
    <Layout>
      <SEO
        keywords={[
          `${name} fruit and vegetable boxes`,
          `${name} vegetable box delivery`,
          `${name} vegetable box pick-up`,
        ]}
        title={`${name} fruit and vegetable boxes`}
        description={`${name} fruit and vegetable box delivery and pick-up`}
      />
      <PageHeader>
        <div className="text-green-600 mb-4">{name}</div> fruit and vegetable
        box delivery and pick-up
      </PageHeader>

      <div className="flex justify-center mt-16">
        <ListBusinessButton />
      </div>

      <ListingContainer>
        <ListingList listings={listings} />
      </ListingContainer>
    </Layout>
  );
}

export const query = graphql`
  query BusinessListingQuery($businessListings: [String]!) {
    allBusinessListing(filter: { id: { in: $businessListings } }) {
      edges {
        node {
          ...businessListingDetails
        }
      }
      totalCount
    }
  }
`;

export default IndexPage;
